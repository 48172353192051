import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Button, Icon } from '@jkhy/vsg-loanvantage-design-system';
import { VideosContext } from '../videos-context';
import './video-page.scss';

function VideoPage() {
  const params: { videoID: string } = useParams();

  return (
    <VideosContext.Consumer>
      {({ videos }) => {
        const targetVideo = videos.find(v => v.id === params.videoID);

        return (
          <div className="video-page">
            <div className="lv-videos-container">
              <div className="back-button mb-l">
                <Link to="/">
                  <Button btnType="secondary" icon="fal fa-arrow-left">Back to all videos</Button>
                </Link>
              </div>
            </div>
            {targetVideo && (
              <div>
                <div className="video-player-wrapper mb-m">
                  <div className="lv-videos-container">
                    <video controls>
                      <source src={`/videos/${targetVideo.fileName}`} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                  </div>
                </div>
                <div className="lv-videos-container">
                  <h1 className="mb-s">{targetVideo.title}</h1>
                  <p className="mb-s">{targetVideo.description}</p>
                  <div className="video-date">
                    <Icon className="mr-xs" icon="fal fa-calendar-alt" size="M" />
                    <p>{targetVideo.date}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }}
    </VideosContext.Consumer>
  );
}

export { VideoPage };
