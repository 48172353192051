import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import '@jkhy/vsg-loanvantage-design-system/dist/styles.css';
import App from './App';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

