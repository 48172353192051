import React from 'react';

export type VideoItem = {
  id: string,
  title: string,
  description: string,
  thumbnail: string,
  duration: string,
  date: string,
  fileName: string
};

export const VideosContext = React.createContext<{ videos: VideoItem[] }>({ videos: [] });
