import React from 'react';
import { Link } from "react-router-dom";
import { Icon } from '@jkhy/vsg-loanvantage-design-system';
import { VideosContext } from '../videos-context';
import './videos-grid-page.scss';

function VideosGridPage() {
  const TITLE_MAX_CHARS = 68;
  const DESCRIPTION_MAX_CHARS = 96;

  const trimTextProp = (textProp: string, targetLength: number) => {
    if (!textProp) {
      throw new Error('Missing video title or description.');
    }
    if (textProp.length > targetLength) {
      return `${textProp.substr(0, targetLength - 3)}...`;
    }
    return textProp;
  }

  return (
    <VideosContext.Consumer>
      {({ videos }) => (
        <div className="lv-videos-container">
          <div className="videos-grid-row">
            {videos && videos.map(v => {
              return (
                <div key={v.id} className="video-grid-col">
                  <Link to={`/video/${v.id}`} className="video-grid-item">
                    <div className="thumbnail mb-s">
                      <img src={`/videos/${v.thumbnail}`} alt="Video thumbnail" />
                      <span className="duration">{v.duration}</span>
                    </div>
                    <h2 className="mb-xs">{trimTextProp(v.title, TITLE_MAX_CHARS)}</h2>
                    <p className="mb-xs">{trimTextProp(v.description, DESCRIPTION_MAX_CHARS)}</p>
                    <div className="video-date"><Icon className="mr-xs" icon="fal fa-calendar-alt" size="S" /><p className="lv-small">{v.date}</p></div>
                  </Link>
                </div>
              );
            })}
            {videos && videos.map(v => {
              return (
                <div key={v.id} className="video-grid-col">
                  <Link to={`/video/${v.id}`} className="video-grid-item">
                    <div className="thumbnail mb-s">
                      <img src={`/videos/${v.thumbnail}`} alt="Video thumbnail" />
                      <span className="duration">{v.duration}</span>
                    </div>
                    <h2 className="mb-xs">{trimTextProp(v.title, TITLE_MAX_CHARS)}</h2>
                    <p className="mb-xs">{trimTextProp(v.description, DESCRIPTION_MAX_CHARS)}</p>
                    <div className="video-date"><Icon className="mr-xs" icon="fal fa-calendar-alt" size="S" /><p className="lv-small">{v.date}</p></div>
                  </Link>
                </div>
              );
            })}
            {videos && videos.slice(0, 2).map(v => {
              return (
                <div key={v.id} className="video-grid-col">
                  <Link to={`/video/${v.id}`} className="video-grid-item">
                    <div className="thumbnail mb-s">
                      <img src={`/videos/${v.thumbnail}`} alt="Video thumbnail" />
                      <span className="duration">{v.duration}</span>
                    </div>
                    <h2 className="mb-xs">{trimTextProp(v.title, TITLE_MAX_CHARS)}</h2>
                    <p className="mb-xs">{trimTextProp(v.description, DESCRIPTION_MAX_CHARS)}</p>
                    <div className="video-date"><Icon className="mr-xs" icon="fal fa-calendar-alt" size="S" /><p className="lv-small">{v.date}</p></div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </VideosContext.Consumer>
  );
}

export { VideosGridPage };
