import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Link,
} from "react-router-dom";
import { VideosGridPage } from './pages/VideosGridPage';
import { VideoPage } from './pages/VideoPage';
import { VideosContext, VideoItem } from './videos-context';
import './app.scss';
import logo from './JackHenryLending_LoanVantage_Logo_navy.png';

function App() {
  const [videoGridItems, setVideoGridItems] = useState<VideoItem[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(`${process.env.PUBLIC_URL}/video-config.json`);
      const { videos }: { videos: VideoItem[] } = await result.json();
      setVideoGridItems(videos);
    };
    fetchData();
  }, []);

  return (
    <Router>
      <header className="lv-videos-app-header">
        <Link to="/" className="logo" style={{ backgroundImage: `url(${logo})` }} />
      </header>
      {videoGridItems && (
        <VideosContext.Provider value={{ videos: videoGridItems }}>

          <Switch>
            <Route path="/video/:videoID">
              <VideoPage />
            </Route>
            <Route path="/">
              <VideosGridPage />
            </Route>
          </Switch>
        </VideosContext.Provider>
      )}
    </Router>
  );
}

export default App;
